import React from 'react';

import { Layout } from '../components/layout';
import { MenuLink } from '../components/menu';
import { Title, Paragraph } from '../components/copy';

const Beschermingsbewind = ({ location }) => (
  <Layout location={location}>
    <div className="mb-4">
      <MenuLink to="/">{'< Terug naar homepagina'}</MenuLink>
    </div>

    <Title>Bewindvoering</Title>

    <Paragraph>
      Beschermingsbewind is professionele hulp voor meerderjarige die
      (tijdelijk) niet in staat zijn om voor hun financiën te zorgen. Het bewind
      wordt op verzoek van betrokkene door de kantonrechter uitgesproken. De
      bewindvoerder heeft de taak om de financiën van de onderbewindgestelde te
      beheren. Een bewindvoerder zorgt voor financiële rust en stabiliteit. Het
      uiteindelijke doel is het bieden van perspectief voor de toekomst.
    </Paragraph>

    <Paragraph>
      Beschermingsbewind bij Middelbeek & Van der Linden Bewindvoering begint
      met een intakegesprek. Daarin maken wij kennis en lichten wij toe wat wij
      voor u of voor uw cliënt kunnen betekenen. Wanneer het bewind gestart is,
      stelt de bewindvoerder een budgetplan op. Ook wordt er gekeken naar de
      mogelijkheden voor bijvoorbeeld de aanvraag van toeslag(en) en het
      verzoeken van kwijtschelding voor gemeente- en waterschapsbelasting.
      Daarnaast zorgt de bewindvoerder ervoor dat de belastingaangifte wordt
      gedaan. Wanneer er sprake is van schulden, zal de bewindvoerder bekijken
      wat de best mogelijke manier is om schuldvrij te raken. Jaarlijks wordt
      verantwoording afgelegd aan de rechtbank. Het lidmaatschap van de
      brancheverenging NBBI zorgt daarnaast voor de continuïteit van kennis en
      kwaliteit.
    </Paragraph>

    <Paragraph>
      Voor aanmelding kunt u contact opnemen met één van ons.
    </Paragraph>
  </Layout>
);

export default Beschermingsbewind;
